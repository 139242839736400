import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { ROUTES } from 'constants/common';
import ArrowIcon from 'images/icons/circle-arrow.svg';

import {
  BackLink,
  BannerWrapperMobile,
  BannerWrapper,
  ContentWrapper,
  Icon,
  Title,
  BackWrapper,
} from './styled';

const AboutEyal = () => {
  const {
    page: { content, title },
  } = useStaticQuery(graphql`
    query AboutEyal {
      page: wpPage(slug: { in: "about-eyal" }) {
        content
        title
      }
    }
  `);

  return (
    <div>
      <BackWrapper>
        <BackLink to={ROUTES.aboutUs}>
          <Icon src={ArrowIcon} />
          <span>back</span>
        </BackLink>
      </BackWrapper>
      <BannerWrapperMobile>
        <StaticImage
          src="../../images/about-eyal/eyal_avramovich_ceo_minebest-mobile.png"
          alt="Eyal Avramovich"
          quality={100}
          placeholder="blurred"
        />
      </BannerWrapperMobile>
      <BannerWrapper>
        <StaticImage
          src="../../images/about-eyal/eyal_avramovich_ceo_minebest.png"
          alt="Eyal Avramovich"
          layout="fullWidth"
          quality={80}
          placeholder="blurred"
        />
      </BannerWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ContentWrapper>
    </div>
  );
};

export default AboutEyal;

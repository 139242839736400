import { Link } from 'gatsby';

import styled from 'styles/styled';

export const BackWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthSmall}px;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

export const BannerWrapper = styled.div`
  display: none;
  left: 50%;
  max-width: 100%;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;

  &::before,
  &::after {
    content: '';
    height: 10vw;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &::before {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    top: 0;
  }

  &::after {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    bottom: 0;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.small} {
    display: block;
  }
`;

export const BannerWrapperMobile = styled(BannerWrapper)`
  display: block;
  height: 100vw;

  @media ${({ theme }) => theme.devices.small} {
    display: none;
  }
`;

export const BackLink = styled(Link)`
  align-items: center;
  display: flex;
  margin-left: 20px;

  @media ${({ theme }) => theme.devices.medium} {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;
  }

  span {
    display: block;
    margin-left: 10px;
    text-transform: uppercase;
  }
`;

export const Icon = styled.img`
  width: 23px;
  transform: rotateZ(180deg);
`;

export const ContentWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthSmall}px;
  margin: -40px auto 0;
  padding: 0 20px 100px;
  position: relative;
  text-align: center;
  z-index: 1;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: -50px;
    text-align: left;
  }

  p + p {
    margin-top: 10px;
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
    margin-bottom: 30px;
  }
`;

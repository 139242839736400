import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { theme } from 'styles/styled';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import AboutEyalContent from 'components/AboutEyal';

const AboutEyal = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "about-eyal" }) {
        ...MetaData
      }
    }
  `);

  wpPage.seo.opengraphUrl = ROUTES.aboutEyal;

  return (
    <Layout backgroundColor={theme.palette.dark}>
      <SEO metadata={wpPage || {}} />
      <AboutEyalContent />
    </Layout>
  );
};

export default AboutEyal;
